import { OptionItem } from '@/common/types';

// A recursive function that creates hashmap of parent options by multiple nested array.
// A dictionary has a set of key which is option's Id has single parent option as object value
export const createParentOptionsHashMap = (options: OptionItem[], parentOption?: OptionItem) => {
  let parentHashMap = {};
  for (const option of options) {
    if (parentOption) {
      parentHashMap = {
        ...parentHashMap,
        [option.id]: parentOption,
      };
    }
    if (option.children && option.children.length > 0) {
      parentHashMap = {
        ...parentHashMap,
        ...createParentOptionsHashMap(option.children, option),
      };
    }
  }
  return parentHashMap;
};
